import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "row" }
const _hoisted_2 = { class: "col-sm-6 chart" }
const _hoisted_3 = { class: "col-sm-6" }
const _hoisted_4 = { class: "chart" }

import {Bar, Line} from 'vue-chartjs'
import {onBeforeMount, ref, Ref} from 'vue'
import {useMetricsStore} from '@/services/stores/MetricsStore'
import {
  Chart,
  Title,
  Tooltip,
  Legend,
  LineElement,
  CategoryScale,
  LinearScale,
  PointElement,
  BarElement, ChartOptions, ChartData,
} from 'chart.js'
import {useI18n} from 'vue-i18n'


export default /*@__PURE__*/_defineComponent({
  __name: 'Metrics',
  setup(__props) {

Chart.register(Title, Tooltip, Legend, LineElement, CategoryScale, LinearScale, PointElement, BarElement)
const {getCurrentYearMetric, loaded, globalYearMetric, canceledYearMetric, completedYearMetric, percentYearMetric} = useMetricsStore()
const {t} = useI18n()
const percentChartOptions: ChartOptions = {
  responsive: true,
  maintainAspectRatio: true,
  scales: {
    y: {
      ticks: {
        callback: function(value) {
          return value + '%';
        }
      }
    }
  }
}
const chartOptions: ChartOptions = {
  responsive: true,
  maintainAspectRatio: true
}
const isLoaded: Ref<boolean> = ref(false)

let globalChartData: ChartData
let percentChartData: ChartData

onBeforeMount(async () => {
  if (!loaded) await getCurrentYearMetric()
  isLoaded.value = true
  globalChartData = {
    labels: Array.from(globalYearMetric.keys()),
    datasets: [
      {
        label: t('services.total'),
        data: Array.from(globalYearMetric.values()),
        backgroundColor: '#ffd500'
      },
      {
        label: t('services.statuses.terminated'),
        data: Array.from(completedYearMetric.values()),
        backgroundColor: '#00ff05'
      },
      {
        label: t('services.statuses.canceled'),
        data: Array.from(canceledYearMetric.values()),
        backgroundColor: '#ff0000'
      }
    ]
  }
  percentChartData = {
    labels: Array.from(globalYearMetric.keys()),
    datasets: [
      {
        label: t('common.placeholders.cancel_percent'),
        data: Array.from(percentYearMetric.values()),
        backgroundColor: '#e81022'
      }
    ]
  }
})

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (isLoaded.value)
        ? (_openBlock(), _createBlock(_unref(Line), {
            key: 0,
            id: "global-chart",
            options: chartOptions,
            data: _unref(globalChartData)
          }, null, 8, ["data"]))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        (isLoaded.value)
          ? (_openBlock(), _createBlock(_unref(Bar), {
              key: 0,
              id: "percent-chart",
              options: percentChartOptions,
              data: _unref(percentChartData)
            }, null, 8, ["data"]))
          : _createCommentVNode("", true)
      ])
    ])
  ]))
}
}

})