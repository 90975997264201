import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "alert alert-success centered-tooltip position-absolute ms-2 mt-2 z-index-sticky",
  role: "alert"
}
const _hoisted_2 = ["current-user-id", "rooms", "room-id", "messages", "theme", "menu-actions"]

import {register} from 'vue-advanced-chat'
import {onBeforeMount, ref, Ref, watch} from 'vue'
import {useRoute} from 'vue-router'
import {useWpChatStore} from '@/services/stores/UseWpChatStore'
import {Chat} from '@/types/Chat'
import {storeToRefs} from 'pinia'
import {Message} from '@/types/Message'
import WhatsAppClient from '@/services/gordaApi/WhatsAppClient'
import {ClientObserver} from '@/services/gordaApi/ClientObserver'
import {useWpClientsStore} from '@/services/stores/WpClientStore'
import DateHelper from '@/helpers/DateHelper'
import {ChatThemes} from '@/services/gordaApi/constants/ChatThemes'
import i18n from "@/plugins/i18n";
import {useClientsStore} from "@/services/stores/ClientsStore";


export default /*@__PURE__*/_defineComponent({
  __name: 'Chat',
  setup(__props) {

const route = useRoute()
const clientId: Ref<string> = ref('')
const { getChats, getMessages, checkPermission, getConfig, setTheme, setActiveChat, filterChat } = useWpChatStore()
const {activeChat, chats, messages, theme } = storeToRefs(useWpChatStore())
const rooms = ref([])
const chatMessages = ref([])
let wpClient: WhatsAppClient
let observer: ClientObserver
const {getWpClient, getWpClients} = useWpClientsStore()
const  { findById, getClients } = useClientsStore()
const showTooltip = ref(false)
const menuActions = [
  { name: 'copy', title: i18n.global.t('common.actions.copy_phone') },
  { name: 'dark', title: i18n.global.t('common.placeholders.' + ChatThemes.DARK) },
  { name: 'light', title: i18n.global.t('common.placeholders.' + ChatThemes.LIGHT) }
]

watch(chats, (newChats) => {
  const chatsSorted = Array.from(newChats.values()).map((chat: Chat) => {
    const client = findById(chat.id)
    return {
      roomId: chat.id,
      roomName: client.name ?? chat.clientName,
      unreadCount: chat.archived? 0 : 1,
      lastMessage: {
        _id: chat.lastMessage.id,
        content: chat.lastMessage.body,
        senderId: chat.lastMessage.fromMe ? clientId.value : chat.id,
        username: chat.clientName,
        date: DateHelper.formatTimestamp(chat.lastMessage.created_at).date,
        timestamp: DateHelper.formatTimestamp(chat.lastMessage.created_at).timestamp,
        index: chat.updated_at,
        saved: true,
        distributed: true,
        seen: chat.lastMessage.fromMe ? true : !chat.archived,
        new: chat.lastMessage.fromMe ? false : !chat.archived
      },
      users: [
        {
          _id: chat.id,
          username: chat.clientName,
        },
        {
          _id: clientId.value,
          username: 'Red Blanca',
          status: {
            state: 'online',
            lastChanged: new Date()
          }
        }
      ]
    }
  })

  rooms.value = chatsSorted.sort((a, b) => {
    return b.lastMessage.index - a.lastMessage.index
  })
}, {deep: true})

watch(messages, (newMessages) => {
  chatMessages.value = newMessages.get(activeChat.value)?.map((message: Message) => {
    const date = DateHelper.formatTimestamp(message.created_at)
    return {
      _id: message.id,
      content: message.body,
      senderId: message.from,
      username: 'message.senderName',
      date: date.date,
      timestamp: date.timestamp,
      disableActions: true,
      disableReactions: true,
      saved: true,
      distributed: true,
      seen: true,
      new: true
    }
  })
}, {deep: true})

function fetchMessages(data: CustomEvent): void {
  const {room} = data.detail[0]
  setActiveChat(room.roomId)
  getMessages(clientId.value, room.roomId)
}

function sendMessage(data: CustomEvent): void {
  const {content, roomId} = data.detail[0]
  const id = DateHelper.unix()
  const newMessage = {
    _id: id,
    content: content,
    senderId: clientId,
    username: 'message.senderName',
    date: DateHelper.formatTimestamp(id).date,
    timestamp: DateHelper.formatTimestamp(id).timestamp,
    disableActions: true,
    disableReactions: true,
    saved: true,
    distributed: false,
    seen: false,
    new: true
  }
  chatMessages.value = [...chatMessages.value, newMessage]
  wpClient.sendMessage(clientId.value, roomId, content)
}

function onUpdate(socket: WhatsAppClient): void {
  console.log(socket.isConnected())
}

function copyText(): void {
  navigator.clipboard.writeText(activeChat.value)
    .then(() => {
      showTooltip.value = true
      setTimeout(() => { showTooltip.value = false }, 2000)
    })
    .catch(err => {
      console.error('Failed to copy: ', err);
    });
}

function filter(data: CustomEvent) {
  const {value} = data.detail[0]
  filterChat(value)
}

function menuActionHandler(data: CustomEvent): void {
  const {action} = data.detail[0]
  switch (action.name) {
    case 'copy':
      copyText()
      break
    case ChatThemes.DARK:
      setTheme(ChatThemes.DARK)
      break
    case ChatThemes.LIGHT:
      setTheme(ChatThemes.LIGHT)
      break
  }
}

onBeforeMount(async () => {
  checkPermission()
  await getClients()
  await getWpClients()
  clientId.value = route.params.id as string
  register()
  getChats(clientId.value)
  getConfig()
  wpClient = WhatsAppClient.getInstance(getWpClient(clientId.value))
  observer = new ClientObserver(onUpdate)
  wpClient.attach(observer)
})

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (showTooltip.value)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("strong", null, _toDisplayString(_unref(activeChat)), 1),
          _createTextVNode(" " + _toDisplayString(_ctx.$t('common.messages.copied')), 1)
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("vue-advanced-chat", {
      "current-user-id": clientId.value,
      rooms: JSON.stringify(rooms.value),
      "room-id": _unref(activeChat),
      messages: JSON.stringify(chatMessages.value),
      height: '100vh',
      theme: _unref(theme),
      "menu-actions": JSON.stringify(menuActions),
      "show-audio": false,
      "rooms-loaded": true,
      "messages-loaded": true,
      "show-add-room": false,
      "show-files": false,
      "show-reaction-emojis": false,
      "room-info-enabled": true,
      "custom-search-room-enabled": true,
      "textarea-auto-focus": false,
      onSearchRoom: filter,
      onMenuActionHandler: menuActionHandler,
      onRoomInfo: copyText,
      onFetchMessages: fetchMessages,
      onSendMessage: sendMessage
    }, null, 40, _hoisted_2)
  ], 64))
}
}

})