import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, resolveComponent as _resolveComponent, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Suspense as _Suspense, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = {
  class: "main-content mt-1 border-radius-lg",
  id: "main"
}

import { onMounted, ref } from 'vue'
import SideBar from '@/components/SideBar.vue'
import NavBar from '@/components/NavBar.vue'
import { usePlacesStore } from '@/services/stores/PlacesStore'
import { useClientsStore } from '@/services/stores/ClientsStore'
import { useDriversStore } from '@/services/stores/DriversStore'
import { useServicesStore } from '@/services/stores/ServiceStore'
import { useWpClientsStore } from '@/services/stores/WpClientStore'
import {useSettingsStore} from "@/services/stores/SettingsStore";


export default /*@__PURE__*/_defineComponent({
  __name: 'Dashboard',
  setup(__props) {

const placesLoaded = ref(false)
const clientsLoaded = ref(false)
const driversLoaded = ref(false)
const servicesLoaded = ref(false)
const wpClientsLoaded = ref(false)
const settingsLoaded = ref(false)

const { getPlaces } = usePlacesStore()
const { getClients } = useClientsStore()
const { getDrivers } = useDriversStore()
const { getHistoryServices, getPendingServices, getInProgressServices } = useServicesStore()
const { getWpClients } = useWpClientsStore()
const { getBranches } = useSettingsStore()

const loadAllData = async () => {
  await Promise.all([
    getPlaces(),
    getClients(),
    getDrivers(),
    getHistoryServices(),
    getPendingServices(),
    getInProgressServices(),
    getWpClients(),
    getBranches()
  ])

  placesLoaded.value = true
  clientsLoaded.value = true
  driversLoaded.value = true
  servicesLoaded.value = true
  wpClientsLoaded.value = true
  settingsLoaded.value = true
}

onMounted(async () => {
  await loadAllData()
  require('@/vendor/js/soft-ui-dashboard')
})


return (_ctx: any,_cache: any) => {
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_openBlock(), _createBlock(_Suspense, null, {
      default: _withCtx(() => [
        (placesLoaded.value && clientsLoaded.value && driversLoaded.value && servicesLoaded.value && wpClientsLoaded.value && settingsLoaded.value)
          ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
              _createVNode(SideBar),
              _createElementVNode("main", _hoisted_2, [
                _createVNode(NavBar),
                _createVNode(_component_router_view, { class: "mt-4 ms-2" })
              ])
            ]))
          : _createCommentVNode("", true)
      ]),
      fallback: _withCtx(() => _cache[0] || (_cache[0] = [
        _createElementVNode("div", { class: "display-6" }, "Loading...", -1)
      ])),
      _: 1
    }))
  ]))
}
}

})