import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createVNode as _createVNode, vModelCheckbox as _vModelCheckbox, withDirectives as _withDirectives, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "container-fluid d-flex justify-content-end" }
const _hoisted_2 = {
  class: "btn btn-primary",
  "data-bs-target": "#create-client",
  "data-bs-toggle": "modal"
}
const _hoisted_3 = { class: "row container-fluid" }
const _hoisted_4 = {
  class: "modal fade",
  id: "create-client",
  tabindex: "-1",
  "aria-labelledby": "create-client",
  "aria-hidden": "true"
}
const _hoisted_5 = {
  class: "modal-dialog modal-dialog-centered",
  role: "document"
}
const _hoisted_6 = { class: "modal-content" }
const _hoisted_7 = { class: "modal-header" }
const _hoisted_8 = {
  class: "modal-title",
  id: "exampleModalLabel"
}
const _hoisted_9 = { class: "modal-body" }
const _hoisted_10 = { class: "form-group" }
const _hoisted_11 = { class: "form-group" }
const _hoisted_12 = { class: "form-check form-switch" }
const _hoisted_13 = { class: "form-check-label" }
const _hoisted_14 = { class: "card-footer text-end" }
const _hoisted_15 = {
  class: "btn btn-info",
  type: "submit"
}

import {useWpClientsStore} from "@/services/stores/WpClientStore"
import Connection from "@/views/whatsapp/Connection.vue"
import {storeToRefs} from "pinia"
import {reactive, ref, watch} from "vue";
import {WpClient} from "@/types/WpClient";
import {ErrorMessage, Field, Form, FormActions} from "vee-validate";
import * as yup from "yup";
import {StrHelper} from "@/helpers/StrHelper";
import {hide} from "@/helpers/ModalHelper";
import { useI18n } from "vue-i18n";
import {WhatsappServices} from "@/constants/WhatsappServices";


export default /*@__PURE__*/_defineComponent({
  __name: 'Index',
  setup(__props) {

const {clients} = storeToRefs(useWpClientsStore())
const {createClient} = useWpClientsStore()
const {t} = useI18n()
const useApi = ref<boolean>(false)

const newClient = reactive<WpClient>({
  id: '',
  alias: '',
  wpNotifications: false,
  chatBot: false,
  assistant: false,
  service: WhatsappServices.BAILEYS
})
const schema = yup.object().shape({
  id: yup.string()
    .required(`${t('validations.required')}`)
    .matches(/^\d+$/, `${t('validations.requiredNumbers')}`)
    .matches(/^\S*$/, `${t('validations.NotSpaces')}`)
    .min(9),
  alias: yup.string()
    .required(`${t('validations.required')}`)
    .min(3, `${t('validations.requiredMinTree')}`)
});

watch(newClient, (clientNew) => {
  newClient.alias = StrHelper.toCamelCase(clientNew.alias?? '')
}, {deep: true})

function create(_values: WpClient, event: FormActions<any>): void {
  if (useApi.value) {
    newClient.service = WhatsappServices.OFFICIAL
  } else {
    newClient.service = WhatsappServices.BAILEYS
  }
  createClient(newClient).finally(() => {
    hide('create-client')
    event.resetForm()
    location.reload()
  })
}

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", null, [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("button", _hoisted_2, _toDisplayString(_ctx.$t('common.actions.create')), 1)
      ]),
      _createElementVNode("div", _hoisted_3, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(clients), (client) => {
          return (_openBlock(), _createElementBlock("div", {
            class: "col-md-6 mt-2",
            key: client.id
          }, [
            _createVNode(Connection, { client: client }, null, 8, ["client"])
          ]))
        }), 128))
      ])
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("div", _hoisted_7, [
            _createElementVNode("h5", _hoisted_8, _toDisplayString(_ctx.$t('wp.actions.create')), 1),
            _cache[3] || (_cache[3] = _createElementVNode("button", {
              type: "button",
              id: "closeEditPasswordModalButton",
              class: "btn-close",
              "data-bs-dismiss": "modal",
              "aria-label": "Close"
            }, [
              _createElementVNode("span", { "aria-hidden": "true" }, "×")
            ], -1))
          ]),
          _createElementVNode("div", _hoisted_9, [
            _createVNode(_unref(Form), {
              onSubmit: create,
              "validation-schema": _unref(schema),
              autocomplete: "off"
            }, {
              default: _withCtx(() => [
                _createElementVNode("div", _hoisted_10, [
                  _createElementVNode("label", null, _toDisplayString(_ctx.$t('wp.fields.alias')), 1),
                  _createVNode(_unref(Field), {
                    name: "alias",
                    type: "text",
                    class: "form-control",
                    id: "alias",
                    placeholder: _ctx.$t('wp.fields.alias'),
                    modelValue: newClient.alias,
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((newClient.alias) = $event)),
                    "aria-label": "Alias",
                    "aria-describedby": "alias-addon"
                  }, null, 8, ["placeholder", "modelValue"]),
                  _createVNode(_unref(ErrorMessage), { name: "alias" })
                ]),
                _createElementVNode("div", _hoisted_11, [
                  _createElementVNode("label", null, _toDisplayString(_ctx.$t('wp.fields.id')), 1),
                  _createVNode(_unref(Field), {
                    name: "id",
                    type: "phone",
                    class: "form-control",
                    id: "id",
                    placeholder: _ctx.$t('wp.fields.id'),
                    modelValue: newClient.id,
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((newClient.id) = $event)),
                    "aria-label": "Phone",
                    "aria-describedby": "phone-addon"
                  }, null, 8, ["placeholder", "modelValue"]),
                  _createVNode(_unref(ErrorMessage), { name: "id" })
                ]),
                _createElementVNode("div", _hoisted_12, [
                  _withDirectives(_createElementVNode("input", {
                    class: "form-check-input",
                    name: "wpApi",
                    type: "checkbox",
                    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((useApi).value = $event))
                  }, null, 512), [
                    [_vModelCheckbox, useApi.value]
                  ]),
                  _createElementVNode("label", _hoisted_13, _toDisplayString(_ctx.$t('wp.placeholders.select_wp_api')), 1)
                ]),
                _createElementVNode("div", _hoisted_14, [
                  _createElementVNode("button", _hoisted_15, _toDisplayString(_ctx.$t('common.actions.submit')), 1)
                ])
              ]),
              _: 1
            }, 8, ["validation-schema"])
          ])
        ])
      ])
    ])
  ], 64))
}
}

})