import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  class: "modal fade",
  tabindex: "-1",
  id: "showServiceModal"
}
const _hoisted_2 = { class: "modal-dialog modal-lg" }
const _hoisted_3 = { class: "modal-content" }
const _hoisted_4 = { class: "modal-header" }
const _hoisted_5 = { class: "modal-title" }
const _hoisted_6 = { class: "modal-body" }
const _hoisted_7 = { style: {"height":"500px"} }
const _hoisted_8 = { class: "row mt-4" }
const _hoisted_9 = { class: "col-6" }
const _hoisted_10 = { class: "d-flex flex-column" }
const _hoisted_11 = { class: "mb-3 text-sm" }
const _hoisted_12 = { class: "mb-2 text-sm" }
const _hoisted_13 = { class: "text-dark ms-sm-2 font-weight-bold" }
const _hoisted_14 = { class: "mb-2 text-sm" }
const _hoisted_15 = { class: "text-dark ms-sm-2 font-weight-bold" }
const _hoisted_16 = { class: "mb-2 text-sm" }
const _hoisted_17 = { class: "text-dark ms-sm-2 font-weight-bold" }
const _hoisted_18 = { class: "mb-2 text-sm" }
const _hoisted_19 = { class: "text-dark font-weight-bold ms-sm-2" }
const _hoisted_20 = { class: "mb-2 text-sm" }
const _hoisted_21 = { class: "text-dark ms-sm-2 font-weight-bold" }
const _hoisted_22 = { class: "mb-2 text-sm" }
const _hoisted_23 = { class: "text-dark ms-sm-2 font-weight-bold" }
const _hoisted_24 = {
  key: 0,
  class: "mb-2 text-sm"
}
const _hoisted_25 = { class: "text-dark ms-sm-2 font-weight-bold" }
const _hoisted_26 = {
  key: 1,
  class: "mb-2 text-sm"
}
const _hoisted_27 = { class: "text-dark ms-sm-2 font-weight-bold" }
const _hoisted_28 = {
  key: 2,
  class: "mb-2 text-sm"
}
const _hoisted_29 = { class: "text-dark ms-sm-2 font-weight-bold" }
const _hoisted_30 = { class: "col-6" }
const _hoisted_31 = {
  key: 0,
  class: "d-flex flex-column"
}
const _hoisted_32 = { class: "mb-3 text-sm" }
const _hoisted_33 = { class: "mb-2 text-sm" }
const _hoisted_34 = { class: "text-dark ms-sm-2 font-weight-bold" }
const _hoisted_35 = { class: "mb-2 text-sm" }
const _hoisted_36 = { class: "text-dark ms-sm-2 font-weight-bold" }
const _hoisted_37 = { class: "mb-2 text-sm" }
const _hoisted_38 = { class: "text-dark ms-sm-2 font-weight-bold" }
const _hoisted_39 = { class: "mb-2 text-sm" }
const _hoisted_40 = { class: "text-dark ms-sm-2 font-weight-bold" }
const _hoisted_41 = { class: "mb-2 text-sm" }
const _hoisted_42 = { class: "text-dark font-weight-bold ms-sm-2" }
const _hoisted_43 = { class: "mb-2 text-sm" }
const _hoisted_44 = { class: "text-dark ms-sm-2 font-weight-bold" }
const _hoisted_45 = {
  key: 0,
  class: "mb-2 text-sm"
}
const _hoisted_46 = { class: "text-dark ms-sm-2 font-weight-bold" }
const _hoisted_47 = { class: "modal-footer" }
const _hoisted_48 = {
  type: "button",
  class: "btn btn-secondary",
  "data-bs-dismiss": "modal"
}

import { PlaceInterface } from '@/types/PlaceInterface'
import { computed, onMounted, reactive } from 'vue'
import Map from '@/components/maps/Map.vue'
import { ServiceList } from '@/models/ServiceList'
import DateHelper from '@/helpers/DateHelper'
import AuthService from '@/services/AuthService'
import {useSettingsStore} from "@/services/stores/SettingsStore";

interface Props {
  service: ServiceList
}


export default /*@__PURE__*/_defineComponent({
  __name: 'ShowServiceModal',
  props: {
    service: {}
  },
  setup(__props: any) {

const props = __props
const location = reactive<Array<PlaceInterface>>([])
const { branchSelected } = useSettingsStore()

onMounted(() => {
  location.push({
    key: props.service.id,
    name: props.service.start_loc.name,
    lat: props.service.start_loc.lat,
    lng: props.service.start_loc.lng
  })
})

const time = computed(() => {
  if (props.service.metadata?.start_trip_at === undefined || props.service.metadata?.end_trip_at === undefined) return '0s'
  return DateHelper.getTime(props.service.metadata?.start_trip_at, props.service.metadata?.end_trip_at) + 'min'
})

const fee = computed(() => {
  if (props.service.metadata?.trip_fee === undefined) return '0' + branchSelected?.currency_code
  return props.service.metadata?.trip_fee + branchSelected?.currency_code
})

const multiplier = computed(() => {
  if (props.service.metadata?.trip_multiplier === undefined) return '1.0'
  return props.service.metadata?.trip_multiplier.toString()
})

const distance = computed(() => {
  if (props.service.metadata?.trip_distance === undefined) return '0.0'
  return (props.service.metadata?.trip_distance / 1000) + 'km'
})

const date = computed(() => {
  return DateHelper.unixToDate(props.service.created_at, 'MM-DD HH:mm:ss')
})

const createdBy = computed(() => {
  return props.service.created_by ? AuthService.getCurrentUser()?.name ?? 'Sistema' : 'Sistema'
})

const canceledBy = computed(() => {
  return props.service.canceled_by ? AuthService.getCurrentUser()?.name ?? 'Sistema' : 'Sistema'
})

const terminatedBy = computed(() => {
  return props.service.terminated_by ? AuthService.getCurrentUser()?.name ?? 'Sistema' : 'Sistema'
})

const assignedBy = computed(() => {
  return props.service.assigned_by ? AuthService.getCurrentUser()?.name ?? 'Sistema' : 'Sistema'
})

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("h5", _hoisted_5, _toDisplayString(_ctx.service.start_loc.name), 1),
          _cache[0] || (_cache[0] = _createElementVNode("button", {
            type: "button",
            class: "btn-close",
            "data-bs-dismiss": "modal",
            "aria-label": "Close"
          }, null, -1))
        ]),
        _createElementVNode("div", _hoisted_6, [
          _createElementVNode("div", _hoisted_7, [
            _createVNode(Map, {
              route: _ctx.service.metadata?.route,
              places: location
            }, null, 8, ["route", "places"])
          ]),
          _createElementVNode("div", _hoisted_8, [
            _createElementVNode("div", _hoisted_9, [
              _createElementVNode("div", _hoisted_10, [
                _createElementVNode("h6", _hoisted_11, _toDisplayString(_ctx.$t('common.placeholders.service_info')), 1),
                _createElementVNode("span", _hoisted_12, [
                  _createTextVNode(_toDisplayString(_ctx.$t('services.fields.start_address')), 1),
                  _createElementVNode("span", _hoisted_13, _toDisplayString(_ctx.service.start_loc.name), 1)
                ]),
                _createElementVNode("span", _hoisted_14, [
                  _createTextVNode(_toDisplayString(_ctx.$t('services.fields.hour')), 1),
                  _createElementVNode("span", _hoisted_15, _toDisplayString(date.value), 1)
                ]),
                _createElementVNode("span", _hoisted_16, [
                  _createTextVNode(_toDisplayString(_ctx.$t('common.fields.status')), 1),
                  _createElementVNode("span", _hoisted_17, _toDisplayString(_ctx.$t(`services.statuses.${ _ctx.service.status }`)), 1)
                ]),
                _createElementVNode("span", _hoisted_18, [
                  _createTextVNode(_toDisplayString(_ctx.$t('common.fields.name')) + " ", 1),
                  _createElementVNode("span", _hoisted_19, _toDisplayString(_ctx.service.name), 1)
                ]),
                _createElementVNode("span", _hoisted_20, [
                  _createTextVNode(_toDisplayString(_ctx.$t('common.fields.phone')), 1),
                  _createElementVNode("span", _hoisted_21, _toDisplayString(_ctx.service.phone), 1)
                ]),
                _createElementVNode("span", _hoisted_22, [
                  _createTextVNode(_toDisplayString(_ctx.$t('services.fields.comment')), 1),
                  _createElementVNode("span", _hoisted_23, _toDisplayString(_ctx.service.comment), 1)
                ]),
                (_ctx.service.created_by !== null)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_24, [
                      _createTextVNode(_toDisplayString(_ctx.$t('common.fields.created_by')), 1),
                      _createElementVNode("span", _hoisted_25, _toDisplayString(createdBy.value), 1)
                    ]))
                  : _createCommentVNode("", true),
                (_ctx.service.canceled_by !== null)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_26, [
                      _createTextVNode(_toDisplayString(_ctx.$t('common.fields.canceled_by')), 1),
                      _createElementVNode("span", _hoisted_27, _toDisplayString(canceledBy.value), 1)
                    ]))
                  : _createCommentVNode("", true),
                (_ctx.service.terminated_by !== null)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_28, [
                      _createTextVNode(_toDisplayString(_ctx.$t('common.fields.terminated_by')), 1),
                      _createElementVNode("span", _hoisted_29, _toDisplayString(terminatedBy.value), 1)
                    ]))
                  : _createCommentVNode("", true)
              ])
            ]),
            _createElementVNode("div", _hoisted_30, [
              (_ctx.service.driver)
                ? (_openBlock(), _createElementBlock("div", _hoisted_31, [
                    _createElementVNode("h6", _hoisted_32, _toDisplayString(_ctx.$t('common.placeholders.route_info')), 1),
                    _createElementVNode("span", _hoisted_33, [
                      _createTextVNode(_toDisplayString(_ctx.$t('services.fields.driver_name')), 1),
                      _createElementVNode("span", _hoisted_34, _toDisplayString(_ctx.service.driver.name), 1)
                    ]),
                    _createElementVNode("span", _hoisted_35, [
                      _createTextVNode(_toDisplayString(_ctx.$t('drivers.fields.plate')), 1),
                      _createElementVNode("span", _hoisted_36, _toDisplayString(_ctx.service.driver.vehicle.plate), 1)
                    ]),
                    _createElementVNode("span", _hoisted_37, [
                      _createTextVNode(_toDisplayString(_ctx.$t('services.fields.time')), 1),
                      _createElementVNode("span", _hoisted_38, _toDisplayString(time.value), 1)
                    ]),
                    _createElementVNode("span", _hoisted_39, [
                      _createTextVNode(_toDisplayString(_ctx.$t('services.fields.distance')), 1),
                      _createElementVNode("span", _hoisted_40, _toDisplayString(distance.value), 1)
                    ]),
                    _createElementVNode("span", _hoisted_41, [
                      _createTextVNode(_toDisplayString(_ctx.$t('services.fields.fee')) + " ", 1),
                      _createElementVNode("span", _hoisted_42, _toDisplayString(fee.value), 1)
                    ]),
                    _createElementVNode("span", _hoisted_43, [
                      _createTextVNode(_toDisplayString(_ctx.$t('services.fields.fee_multiplier')), 1),
                      _createElementVNode("span", _hoisted_44, _toDisplayString(multiplier.value), 1)
                    ]),
                    (_ctx.service.assigned_by !== null)
                      ? (_openBlock(), _createElementBlock("span", _hoisted_45, [
                          _createTextVNode(_toDisplayString(_ctx.$t('common.fields.assigned_by')), 1),
                          _createElementVNode("span", _hoisted_46, _toDisplayString(assignedBy.value), 1)
                        ]))
                      : _createCommentVNode("", true)
                  ]))
                : _createCommentVNode("", true)
            ])
          ])
        ]),
        _createElementVNode("div", _hoisted_47, [
          _createElementVNode("button", _hoisted_48, _toDisplayString(_ctx.$t('common.actions.close')), 1)
        ])
      ])
    ])
  ]))
}
}

})